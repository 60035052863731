@import url(https://fonts.googleapis.com/css2?family=Dosis:wght@600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=PT+Serif&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Oswald&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Ubuntu+Mono:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  background: #fafafa;
  min-height: 100vh;
}
input[type="number"] {
  -moz-appearance: textfield;
}
::-webkit-datetime-edit-year-field:not([aria-valuenow]),
::-webkit-datetime-edit-month-field:not([aria-valuenow]),
::-webkit-datetime-edit-day-field:not([aria-valuenow]) {
  color: transparent;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
/* html {
  overflow: auto;
} */
label {
  width: 10vw;
}

::-webkit-scrollbar {
  width: 6px;
  background-color: #fafafa;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 6px 6px 6px white;
  border-radius: 10px;
}
.ant-col-12 {
  max-width: unset !important;
}

::-webkit-scrollbar-thumb {
  border-radius: 40px;
  background: #42332e;
}
::-webkit-scrollbar-thumb:window-inactive {
  background: #4c5a40;
}

/* Font Styles */

@font-face {
  font-family: PoppinsR;
  src: url(/static/media/Poppins-Regular.06b69e77.woff) format("woff");
  font-weight: normal;
}
@font-face {
  font-family: PoppinsM;
  src: url(/static/media/Poppins-Medium.f3e1bf20.woff) format("woff");
}
@font-face {
  font-family: PoppinsB;
  src: url(/static/media/Poppins-Bold.12c0c2e9.woff) format("woff");
}
@font-face {
  font-family: PoppinsSB;
  src: url(/static/media/Poppins-SemiBold.8d6ba615.woff) format("woff");
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  background: rgba(76, 90, 64, 0.16) !important;
}

input:-webkit-autofill {
  color: rgba(0, 0, 0, 0.6);
}
button {
  outline: none !important;
}
body {
  font-family: "Dosis" !important;
  outline: none;
  position: relative;
}
.ai {
  width: 20vw;
  border-radius: 3.5px;
  padding: 1vh;
  font-size: 1.7vh;
  border: 1px solid #e0e0e0;
  height: 6vh;
  margin-top: 2vh;
  margin-bottom: 2vh;
}

/* Common Styles */

.color-code {
  background-color: #0277bd;
}
.border-radius {
  border-radius: 16px;
}
.font-poppins-regular {
  font-family: "Dosis";
  font-size: 12px;
}
.btn-code {
  border: none;
  box-sizing: border-box;
  background: #4c5a40;
  border-radius: 16px;
  padding: 8px 16px;
  font-weight: 600;
  letter-spacing: 0.75px;
  outline: none;
  cursor: pointer;
  color: #ffffff;
}
.btn-code:hover,
.easy-edit-button:hover,
.ant-btn:hover {
  background: rgba(76, 90, 64, 0.16) !important;
}
.invici-btn-code {
  background: transparent;
  border: none;
  color: black;
}

.btn-code:active,
.ant-btn:active,
.ant-btn-primary:active {
  background: linear-gradient(
      0deg,
      rgba(76, 90, 64, 0.12),
      rgba(76, 90, 64, 0.12)
    ),
    #4c5a40 !important;
}
.input-code {
  background: transparent !important;
  border: 1px solid #4c5a40 !important;
  box-sizing: border-box !important;
  padding: 13.5px 16px !important;
  color: #4c5a40 !important;
  font-size: 14px !important;
  border-radius: 70px;
  outline: none;
}
.input-code:hover,
input:hover {
  background: rgba(76, 90, 64, 0.16);
  color: #4c5a40 !important;
}
.input-code::-webkit-input-placeholder, input::-webkit-input-placeholder {
  color: #4c5a40 !important;
}
.input-code:-ms-input-placeholder, input:-ms-input-placeholder {
  color: #4c5a40 !important;
}
.input-code::-ms-input-placeholder, input::-ms-input-placeholder {
  color: #4c5a40 !important;
}
.input-code::placeholder,
input::placeholder {
  color: #4c5a40 !important;
}

.product-fnctn .ant-btn {
  background-color: transparent !important;
}

.ant-btn,
.ant-modal-footer button + button,
.ant-btn-primary,
.easy-edit-button {
  border: none !important;
  box-sizing: border-box !important;
  background: #4c5a40 !important;
  border-radius: 16px !important;
  font-weight: 600 !important;
  letter-spacing: 0.75px !important;
  outline: none !important;
  cursor: pointer !important;
  color: white !important;
  font-family: "Dosis" !important;
}

.ant-btn {
  padding: none !important;
}
.ant-btn-dashed svg {
  color: black !important;
}

.easy-edit-component-wrapper input {
  border: none;
}
.easy-edit-inline-wrapper {
  display: flex;
  flex-direction: column;
}
.easy-edit-button-wrapper {
  margin-top: 20px !important;
}
.text-clr {
  color: rgba(255, 255, 255, 0.87);
}
.poppinsr {
  font-family: "Dosis";
}

/* Styles */

.login {
  height: 99vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.login-inpt {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.login-inpt input {
  width: 243px;
  height: 40px;
  border: none;
  padding: 11px 16px;
  box-sizing: border-box;
  margin-top: 20px;
  outline: none;
}
.login input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.87);
}
.login input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.87);
}
.login input::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.87);
}
.login input::placeholder {
  color: rgba(255, 255, 255, 0.87);
}
.login-btn {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.home {
  background-color: #fafafa;
  height: auto;
  padding-top: 47px;
  padding-left: 108px;
  padding-right: 108px;
  width: 100%;
  min-height: 100vh;
  padding-left: 25vw;
  padding-top: 20vh;
}
.home-product {
  padding-top: 9vh;
  padding-left: 13vh;
  padding-right: 13vh;
}
.home-product-container {
  background-color: white;
  height: auto;
  width: 80%;
}
.navbar {
  z-index: 1000;
  width: 20%;
  background: #ffffff;
  height: 100vh;
  border: 1px solid rgba(0, 0, 0, 0.08);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 20vh;
  left: 0px;
  top: 0px;
  position: fixed !important;
}
.navbar-buttons {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-evenly;
}
.navbar-buttons button {
  width: 100%;
  height: 7vh;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  background: transparent;
  color: black;
  font-weight: 600;
  font-size: 1.6vh;
  line-height: 150%;
  cursor: pointer;
  outline: none;
  text-align: left;
  padding-left: 7.5vh;
}
.navbar-buttons button:hover {
  background: rgba(76, 90, 64, 0.16);
  color: #4c5a40 !important;
}
.navbar-buttons button:active {
  background: rgba(76, 90, 64, 0.12);
}
.navbar-active {
  border-right: 3px solid #4c5a40 !important;
  background: rgba(76, 90, 64, 0.16) !important;
}
.navbar-active .MuiButton-contained {
  margin-top: unset !important;
}

.signout {
  border: none;
  background: transparent;
  font-style: normal;
  font-weight: 600;
  font-size: 1.6vh;
  line-height: 150%;
  color: #000000;
  cursor: pointer;
  outline: none;
  border-radius: 55px;
  box-sizing: border-box;
  padding: 8px 16px;
}
.signout:hover {
  background: rgba(76, 90, 64, 0.16);
  color: #4c5a40 !important;
}
.signout:active {
  background: rgba(76, 90, 64, 0.12);
}

.more-btn {
  font-family: "Dosis";
  border: none;
  background: transparent;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: #000000;
  cursor: pointer;
  outline: none;
  text-align: left;
}
.banner-upload {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding-bottom: 10vh;
  background: #fafafa;
}
.head-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.head-main p {
  margin-bottom: 0;
}

.sub-head {
  font-family: Oswald;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 16px;
  /* identical to box height, or 67% */

  color: #121212;
  padding-left: 1vw;
}
.sub-head-content {
  font-family: "Dosis";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */

  color: rgba(0, 0, 0, 0.87);
}
.MuiButton-contained {
  width: 10vw !important;
  height: 4vh !important;
  font-size: 1.6vh !important;
  margin-top: 2vh !important;
}
.navbar-buttons .MuiButton-contained {
  width: 100% !important;
  height: 6vh !important;
  background-color: #f7f7f7 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  box-shadow: unset !important;
  margin-top: 0 !important;
}
/* .navbar-buttons .MuiButton-contained:hover {
  background-color: rgba(247, 247, 247, 0.12) !important;
} */
.MuiFormControl-root {
  width: 100% !important;
}
.MuiButton-label {
  font-family: "Dosis";
}
.MuiButton-label p {
  margin-bottom: 0 !important;
  font-family: "Dosis";
}
.MuiInputLabel-outlined {
  font-family: "Dosis";
}
.about-content {
  border-top: 1px solid rgba(0, 0, 0, 0.08);
  padding-bottom: 5vh;
}
.header {
  height: 9vh;
  width: 100%;
  background: linear-gradient(0deg, #114376, #114376);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header img {
  position: fixed;
  width: 13vw;
  top: 2vh;
  left: 4vw;
  cursor: pointer;
}
.header-content {
  position: fixed !important;
  right: 0 !important;
  justify-content: space-between;
  width: 5vw;
  color: white;
  margin-right: 5vw;
}
.header-content p {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 0 !important;
  cursor: pointer;
}
.header-content svg {
  color: white;
  font-size: 25px;
}
.loader {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 1000;
}
.testimon-table {
  width: 60%;
  background: white;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  margin-top: 4vh;
}
.tm-h tr {
  border-bottom: 1px solid #eeeeee;
}
.tm-c tr {
  border-bottom: 1px solid #eeeeee;
}
.tm-h tr th {
  height: 8vh;
  padding: 3vh;
}
.tm-c tr td {
  height: 8vh;
  padding: 3vh;
}
.tm-c tr td svg {
  font-size: 3vh;
  cursor: pointer;
}
.tm-e .MuiButtonBase-root {
  height: 3vh !important;
  width: 7vw !important;
}
.head-banner {
  display: flex;
  justify-content: space-between;
  width: 80%;
}
.sbcw {
  margin-top: 5vh;
}
.add-p-q {
  border: none;
  background: transparent;
  cursor: pointer;
}
.add-p-q svg {
  font-size: 3vh;
}
.p-add-m .ant-modal-footer {
  display: none !important;
}
.sproduct-table {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
}

.ant-collapse p {
  font-size: 1.5vh !important;
  height: 5vh !important;
  overflow: hidden !important;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  height: 8vh;
}
.manage-pro {
  padding-left: 50vh !important;
  padding-top: 20vh;
  padding-right: 5vw !important;
}
.poppinsr p {
  text-align: left !important;
}
.MuiButton-label p {
  text-align: left !important;
}
.ant-tree-title {
  font-size: 18px !important;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  overflow: hidden !important;
}
.ant-popover {
  z-index: 900 !important ;
}
.about-content select {
  width: 20vw;
  height: 5vh;
  outline: none;
}
.about-content label {
  width: 10vw;
}
.ReactCrop {
  width: 80%;
}

.image-crop-c {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.crop-n-add-c {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.ant-alert {
  width: 20%;
}
.delete-img-icon {
  position: absolute;
  top: -10px;
  right: -10px;
  color: white;
  border-radius: 50%;
  border: transparent;
  cursor: pointer;
  background: black;
  width: 30px;
  height: 30px;
}
.delete-img-icon:hover {
  background: red;
}
.qnty-delete-btn {
  background: black;
  color: white;
  border: none;
  width: 30px;
  height: 30px;
  cursor: pointer;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.qnty-delete-btn:hover {
  background: red;
}
.thread-create-imagefield {
  display: flex !important;
  align-items: center !important;
  width: 100% !important;
  justify-self: space-between !important;
}
.uploaded-image-forum {
  width: 20em !important;
  border-radius: 8px;
  margin-bottom: 4vh;
  margin-left: 2vh;
}
.header-up-btn svg {
  font-size: 5vh;
  color: #666 !important;
}
.alt-img-btn-upload {
  background: transparent;
  border-radius: 8px;
  border: none;
  width: 15em;
  color: #666;
  height: 7vh;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  line-height: 1.7vh;
  cursor: pointer;
  text-align: center;
  align-items: center;
  align-content: center;
  font-size: 16px;
  line-height: 7vh;
  font-weight: 600;
}
.thread-title {
  background: rgba(17, 67, 118, 0.08);
  border-radius: 4px 4px 0px 0px;
  border: none;
  border-bottom: 1px solid #114376;
  width: 100%;
  height: 7vh;
  padding: 4vh;
  box-sizing: border-box;
  color: white;
  outline: none;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  display: flex;
  align-items: flex-end;

  color: rgba(0, 0, 0, 0.87);
}
.rdw-editor-main {
  background: white;
  min-height: 40vh;
  padding: 0 5vw 0 5vw;
}
.rdw-editor-wrapper {
  border: 1px solid #c9c9c9;
  margin-top: 5vh;
}
.rdw-editor-toolbar {
  justify-content: space-between !important;
  padding-left: 5vw !important;
  padding-right: 5vw !important;
}
.rdw-inline-wrapper {
  justify-content: flex-end !important;
}
.rdw-option-wrapper {
  padding: 2vh 1vw !important;
}
.draft-font {
  font-family: "PT Serif", serif !important;
}
.testimon-table {
  width: 80%;
  background: white;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  margin-top: 4vh;
}
.tm-h tr {
  border-bottom: 1px solid #eeeeee;
}
.tm-c tr {
  border-bottom: 1px solid #eeeeee;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
}
.tm-h a {
  border-bottom: 1px solid #eeeeee;
}
.tm-c a {
  border-bottom: 1px solid #eeeeee;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
}
.tm-h tr th {
  height: 8vh;
  padding: 3vh;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */

  color: #000000;
}
.tm-c tr td {
  height: 8vh;
  padding: 3vh;
}

.tm-c a td {
  width: 100%;
}
.tm-c tr td svg {
  font-size: 3vh;
  cursor: pointer;
}
.rqst-header {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.rqst-header svg {
  font-size: 2vh !important;
  cursor: pointer;
}
.blog-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5vh;
}
.blog-title {
  width: 100%;
  height: 50vh;
  object-fit: cover;
}
.blog-s-content {
  width: 80%;
  padding-top: 8vh;
}
.blog-s-content img {
  max-width: 50vw !important;
}
.blog-s-content p {
  font-family: "PT Serif", serif;
}
.rdw-image-imagewrapper img {
  max-width: 60vw;
}
.blog_d {
  font-family: Montserrat !important;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 135.94%;
  /* identical to box height, or 19px */

  color: rgba(0, 0, 0, 0.6);
}
.blog_title {
  font-family: Oswald !important;
  font-style: normal;
  font-weight: normal;
  font-size: 38px;
  line-height: 135.94%;
  /* or 52px */

  color: #121212;
}
.course-create-modal {
  display: flex;
  flex-direction: column;
}
.banner-wrap {
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100vh;
}
.file-upload {
  border: 1px solid black;
  width: 100%;
  background: linear-gradient(
    0deg,
    rgba(17, 67, 118, 0.08),
    rgba(17, 67, 118, 0.08)
  );
  border: 1.64162px dashed #114376;
  height: 20vh;
  display: flex;
  justify-content: center;
  flex-direction: column !important;
  align-items: center;
  font-family: "Ubuntu Mono";
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
  /* identical to box height, or 164% */

  letter-spacing: 1.23121px;
  text-transform: uppercase;
}
.ant-modal-title {
  font-family: "Oswald" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 24px !important;
  line-height: 16px;
  /* identical to box height, or 67% */

  color: #000000 !important;
}
.ant-pagination-item {
  margin-right: 0 !important;
}
.map {
  height: 40vh;
  width: 80vh;
}

